/**
 * Jquery UI DatePicker for delivery_date
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-09-21
 *
 * @param   {string}    conveyorId
 * @param   {boolean}   isPreco
 * @param   {string}    remiseStock
 */
function initCalendar(conveyorId, isPreco, remiseStock) {
    var inputTarget = $('#delivery-date-' + conveyorId);
    var dates = inputTarget.attr('enabled-dates');
    var hasUltraFrais = inputTarget.attr('has-ultra-frais');
    var arrayDates = dates.split('|');
    var dateToday = new Date();

    if (inputTarget.attr('conveyor-id')) {
        conveyorId = inputTarget.attr('conveyor-id');
    }

    array = checkHourlyRules(arrayDates, dateToday, conveyorId);
    inputTarget.datepicker({
        dateFormat: 'dd/mm/yy',
        minDate: dateToday,
        maxDate: new Date(dateToday.getFullYear(), dateToday.getMonth() + 3, 0),
        defaultDate: "+1d",
        altField: '#order_delivery_date',
        altFormat: 'yy-mm-dd',
        firstDay: 1,
        beforeShowDay: function (date) {
            return enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock);
        },
    });
}

/**
 * We check if we have availability for this month
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 * @since   2022-11-15
 */
function checkAvailibiltyCurrentMonth() {
    var currentDay = new Date();
    var nbrDaysOfCurrentMonth = this.daysInMonth(currentDay.getMonth()+1, currentDay.getFullYear());// "+1" because "getMonth" starts counting at 0, and goes until 11.
    var nbrDaysOfCurrentMonthUnselected = document.getElementsByClassName("ui-datepicker-unselectable").length - document.getElementsByClassName("ui-datepicker-other-month").length;
    var isNoAvailibilty = (nbrDaysOfCurrentMonth == nbrDaysOfCurrentMonthUnselected) ? true : false;
    if (isNoAvailibilty) {
        document.querySelector('.ui-datepicker-next').click();
    }
}

/**
 * We check the number of days of the current month. If we use the "year" param, it's because the specificity of february.
 *
 * @author  Youssef Mabrouk  <yma@wshop.com>
 * @since   2022-11-15
 * @param   {integer}   month
 * @param   {integer}   year
 */
function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

/**
 * @description Check if the showing date is enabled or not, according to certain rules
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-09-21
 *
 * @param   {string}    date
 * @param   {array}     array
 * @param   {boolean}   ultra_frais
 * @param   {integer}   conveyor_id
 * @param   {boolean}   isPreco
 * @param   {string}    remiseStock
 *
 * @returns {boolean}
 */
function enableDeliveryDate(date, array, ultraFrais, conveyorId = 0, isPreco, remiseStock) {
    if (isPreco == "1") {
        var datePreco = $.datepicker.parseDate('dd/mm/yy', remiseStock);
        datePreco.setDate(datePreco.getDate() - 1);
        // We remove a day because the variable in php already receives 2 days and that the calendar starts from base to +1 in order to respect the rule of delivery D + 2 after date of restocking
        var formattedDatePrecoYear = $.datepicker.formatDate('yy-mm-dd', datePreco);
    }
    var formatDate = $.datepicker.formatDate('mm-dd', date);
    var formatDateYear = $.datepicker.formatDate('yy-mm-dd', date);
    var currentDay = date.getDay();
    conveyorId = (conveyorId != 0) ? conveyorId : $('#conveyor_id').val();
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];
    var specialDates = ['04-09', '04-10', '04-15'];

    // "Saturday" or "Sunday" aren't working days for PM
    if ([6, 0].includes(currentDay)) {
        return false;
    }

    // If the current basket contains a product with pre order attribute
    if (isPreco == '1') {
        if (formatDateYear < formattedDatePrecoYear) {
            return false
        }
    }

    // If the selected conveyor is "Chrono Fresh"
    if (conveyorId == '51') {
        var arrayDaysHigh = [0, 1];
        var arrayDaysLow = [0, 1, 2, 4, 6];

        // WP-26451 activation de tous les jours sauf le week-end
        if (formatDate >= '02-01' && formatDate <= '03-31') {
            return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
        } else if (formatDate >= '09-01' && formatDate <= '06-30') {
            // if it's the "high season" we don't show : Sundays and Mondays
            if (arrayDaysHigh.includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '01-01' && formatDate <= '01-31') {
            // for january we show all working days
            if ([0, 6].includes(currentDay)) {
                return false;
            }
        } else if (formatDate >= '07-01' && formatDate <= '08-31') {
            // if it's the "low season" we only show : Wednesdays and Thursdays
            if (arrayDaysLow.includes(currentDay)) {
                return false;
            }
        }

        // between 30/12 and 05/01, all days are disabled
        if (formatDate >= '12-30' || formatDate <= '01-05') {
            return false;
        }

        // until 29/09, the following days are deactivated: Sunday/ Monday/ Saturday
        if (formatDate < '09-29' && formatDate > '01-31' && [0, 1, 6].includes(currentDay) && !specialDates.includes(formatDate)) {
            return false;
        }

    } else if (arrayDpd.includes(conveyorId) || arrayFedex.includes(conveyorId)) {
        // From 30/12 to 04/01, all days are disabled
        if (formatDate >= '12-30' || formatDate <= '01-04') {
            return false;
        }
    }

    // If the current basket contains a product with "Ultra frais" attribute
    if (ultraFrais == '1') {
        var previousDay = formatDate - 1;
        if (currentDay == 1 || array.includes(previousDay)) {
            return false;
        }
    }

    // we disable the next day of non-deliverable dates
    var previousDay = $.datepicker.parseDate('mm-dd', formatDate);
    if (['51', '37'].includes(conveyorId)) {
        previousDay.setDate(previousDay.getDate() - 2);
    } else {
        previousDay.setDate(previousDay.getDate() - 1);
    }
    previousDay = $.datepicker.formatDate('mm-dd', previousDay);
    if (array.includes(previousDay) && !specialDates.includes(formatDate)) {
        return false;
    }

    return [array.indexOf(formatDate) == -1 && array.indexOf(formatDateYear) == -1];
}

/**
 * @description Check hourly rules for PM's conveyors
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-10-24
 *
 * @param {Array}   array
 * @param {string}  date
 * @param {string}  conveyorId
 *
 * @returns {Array}
 */
function checkHourlyRules(array, date, conveyorId)
{
    var hours = date.getHours();
    var arrayChronopost = ['51', '37'];
    var arrayFedex = ['36', '54'];
    var arrayDpd = ['52', '53'];

    // if the selected conveyor is "ChronoFresh" or "ChronoExpress"
    if (arrayChronopost.includes(conveyorId)) {
        // add D+1
        date.setDate(date.getDate() + 1);
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 12) {
            // add D+2
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    } else if (arrayFedex.includes(conveyorId) || arrayDpd.includes(conveyorId)) {
        array.push($.datepicker.formatDate('yy-mm-dd', date));
        array = checkWorkingDays(date, array);
        if (hours >= 8) {
            // add D+1
            date.setDate(date.getDate() + 1);
            array.push($.datepicker.formatDate('yy-mm-dd', date));
            array = checkWorkingDays(date, array);
        }
    }
    return array;
}

/**
 * Checks if current day is a working day
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-11-04
 *
 * @param   {Datetime}  date
 * @param   {Array}     array
 * @returns
 */
function checkWorkingDays(date, array)
{
    currentDay = date.getDay();
    while ([6, 0].includes(currentDay)) {
        date.setDate(date.getDate() + 1);
        currentDay = date.getDay();
        array.push($.datepicker.formatDate('yy-mm-dd', date));
    }
    return array;
}

/**
 * Verifies the integrity of a date according to the given rules
 * 
 * @author Stefane DR <sdr@wshop.com>
 *
 * @param {string} date
 *
 * @returns {boolean}
 */
function verifyDateIntegrity(date) {
    date = new Date(date);
    let today = new Date();
    if (date < today) {
        return false;
    }
    let conveyorId = $('#conveyor_id').val();
    let inputTarget = $('#delivery-date-' + conveyorId);
    let dates = inputTarget.attr('enabled-dates');
    let hasUltraFrais = inputTarget.attr('has-ultra-frais');
    let arrayDates = dates.split('|');
    let isPreco = inputTarget.attr('is-preco');
    let remiseStock = inputTarget.attr('remise-stock');
    let verifDate = enableDeliveryDate(date, arrayDates, hasUltraFrais, conveyorId, isPreco, remiseStock);
    return verifDate;
}