function btnLoaderTrigger(evt) {
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        // Disable prevent.default on form in order to allow the submit
        if (!$(this).parents('form').length) {
            evt.preventDefault();
        }

        loader
            .removeAttr("style")
            .parent()
            .addClass("loading");

        if ($(this).attr("href")) {
            window.location.assign($(this).attr("href"));
        }

        //ajax moveProductFromWishlistToCart layer #topLogin
        if (this == 'btn_top_add_wishlist' && $(this).parent().hasClass('see_cart')) {
            selectedProducts = [];
            $("#cart_list .remove_top_wishlist").each(function(index) {
                var id = $(this).attr("data-id");
                selectedProducts.push(id);
            });
            moveProductFromWishlistToCart(selectedProducts);
        }
    }

    // loader search tete de gondole "nos incontournables"
    setTimeout(function() {
        $('.form_submit').removeClass('loading');
    }, 1000);

}

function triggerDropdown(elem, trigger, toDrop) {

    if (!$(elem).hasClass('actif')) {
        $(elem).addClass('actif');
        $(elem).next(toDrop).slideDown(400, function () {

            $(this).addClass('actif');
        });
        if (toDrop === '.selected_options') {
            if ($(elem).next(toDrop).hasClass('scrollable')) {
                $(elem).next(toDrop).overlayScrollbars({});
            }
        }
    } else {

        $(elem).removeClass('actif');
        $(elem).next(toDrop).slideUp(400, function () {

            $(this).addClass('actif');
        });
        if (toDrop === '.selected_options') {
            if ($(elem).next(toDrop).hasClass('scrollable')) {
                $(elem).next(toDrop).overlayScrollbars().destroy();
            }
        }
    }
}

function displayObjects() {

    if (!$(elem).hasClass('actif')) {
        $('.selected_options.actif').slideUp(400).removeClass('actif');
        $('.trigger_dropdown.actif').removeClass('actif');
        $(elem).addClass('actif');
        $(elem).next('.selected_options').addClass('actif').slideDown(400);
    } else {
        $(elem).removeClass('actif');
        $('.selected_options.actif').slideUp(400).removeClass('actif');
    }
}

$(function() {
    $(document).on("click", ".button", btnLoaderTrigger);
});

function closeSearch() {
    var search = $('.wrap_search_field');

    search.removeClass('actif');
    $('#search_home').blur();
    $('#shad_menu').removeClass('actif search_active');
    $('#top_wrapper').removeClass('active_rollover');
    $('#trigger_recherche').removeClass('triggered');
}

function cloneCtaAchatExpressAjax() {
    $('#list_item .wrapperProdVertical').each(function (e) {
        var expressBtn = $(this).find('.wrap_rolloverproduit');
        var productPrice = $(this).find('.pricetag');
        var is_precommande = $(this).find('.achat_express_submit').attr('data-is-preco');
        var text_precommande = $(this).find('.achat_express_submit span');

        expressBtn.insertAfter($(this).children('.product_infos'));

        if (is_precommande === 0) {
            text_precommande.text(Translator.translate('add_basket_preco'));
        } else {
            productPrice.clone().insertAfter(text_precommande);
        }
    });

}

$('.close_search, #shad_search').on("click touch", function () {
    closeSearch();
});

$('#search_home').keypress(function (e) {

    var key = e.which;
    var value =  $('#search_home').val();


    if (key === 13 || value.length >= 2) {

        $('body').addClass('category search_in');

        setTimeout(function() {
            closeSearch();
            if (value.length <= 2) {
                cloneCtaAchatExpressAjax();
            }

        }, 1500);

    }
});

$("#search_home").on('input', function () {
    var value = $("#search_home").val();


    if (value.length >= 3) {
        if ($('body').hasClass('homepage')) {
            $('body').removeClass('homepage');
            $('body').addClass('category search_in');
        }
        $('body').addClass('category search_in');
    }

    if (value.length === 0 && !$('body').hasClass('homepage')) {
        $('body').addClass('category search_in');
        $('body').removeClass('homepage');
    }

});

$('body').on('click', '.search_recap', function () {
    $('#trigger_recherche').click();
    $('#search_home').focus();
});

var main_product_select_html = $('.product_infos_form.not_achat_express #product_selects').html();

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type("orders_by_type", order_type, ROOT_PATH))
        $("#order_" + order_id).click();
    $("html, body").animate(
        {
            scrollTop: $('[data-btn-name="order_section"]').offset().top - 100,
        },
        500
    );
}

function changeLocale(locale, uri, site) {
    $.ajax({
        url: path_relative_root + create_link('ajax_get_uri_by_lang'),
        type: "post",
        data: {lang: locale, url: uri, site: site},
        success: function(data) {
            if (data != "") {
                var force = "?force";

                location.href = data + force;
            }
        },
    });
}

function goBack(toUrl) {
    if (toUrl) {
        window.location.href = path_relative_root;
    } else {
        var url = window.location.href;
        if (url.indexOf("#search") > -1) {
            var new_url = url.substring(0, url.indexOf("#search"));
            window.location.href = new_url;
        } else {
            window.history.back();
        }
    }
}

//Utilisé pour le scroll
function fix_rayon_display() {
    if ($("#list_item .item").length > 0) {
        var items = $("#list_item .item");
        var itemCount = $("#list_item .item").length;

        if (itemCount % 3 == 2) {
            $("<div class=\"item empty\"></div>").insertAfter(items.last());
        }
    }
}

var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

if ($('#completeLookTitle').length === 0) {
    $("#mightLikeTitle").addClass("is-active");
    $(".associations_by_rayon").removeClass("is-hidden");
};

function checkSelectedInput() {
    var inputTaille = $('.product_infos_form.not_achat_express').find('#tailleProd');
    if (inputTaille.length && inputTaille.val() == '') {
        $('#sizeerror').slideDown();
    }
}

/***** PROD ASSOCS DATA LAYERS *****/

function dataLayer_associated_product () {

    if (window.$('#gondole_aimerez')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';
        if (product_id !== '') {

            data_layer_products.forEach(function(item) {
                if ( item.id == product_id ) {
                    productObj = item;
                }
            })
            productObj.url = $(this).children("a").attr("href");
            var prod = productObj;
            var product_link = prod !== undefined ? prod.list : '';
            delete prod.ean;
            delete prod.ref;
            delete prod.sku;
            delete prod.refSizeColor;


            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list':product_link},      // Optional list property.
                        'products': [prod]
                    }
                },
                'eventCallback': "function () {document.location = productObj.url}"
            });
        }
    }
}


function hide_lightbox_send_info() {
    closeMultiShad('ligthbox_send_store_information');
}



// Function used on quantity selector evolution
function updateProductQuantity(action, prod_id) {
    var qte_stock,
    qte_input,
    plus_selector,
    minus_selector;
    var is_wishlist = $('#wishlist').length > 0;

    // Recover html selectors
    plus_selector = $("#ligne_qte .qte_plus");
    minus_selector = $("#ligne_qte .qte_minus");

    // Get product stock quantity and input quantity selected values
    qte_stock = $('#product_stock_counter').val();
    qte_stock = (qte_stock != '') ? qte_stock : 0;
    qte_input = $('#ligne_qte .qte_selected').val();
    qte_input = (qte_input != '') ? qte_input : 0;

    if (action == 'plus') {
        qte_input++;

        // Remove disabled classes if necessary
        if ((qte_input > 1) && (minus_selector.hasClass('selector_disabled'))) {
            minus_selector.removeClass('selector_disabled');
        }

        // Update form
        if (qte_input <= qte_stock) {
            $('#qteProd').val(qte_input);
        } else {
            qte_input --;
            plus_selector.addClass('selector_disabled')
        }

    } else if (action == 'minus') {
        qte_input --;

        // If qte stored in input can be decremented
        if ((qte_input > 1) || (qte_input == 1)) {
            $('#qteProd').val(qte_input);
        }

        // add diabled class if needed
        if (qte_input == 1) {
            if (!minus_selector.hasClass('selector_disabled')) {
                minus_selector.addClass('selector_disabled');
            }
        }
    }

    // Update input holding stock counter to add security : if qte = 0 you can't add it to your cart
    getAvailableQuantity( prod_id, qte_input, 'product', '', is_wishlist );

    // Change the width of the input field based on value
    if (qte_input > 9 && qte_input < 100) {
        $("#qteProd").css("width", "50px");
    } else if (qte_input > 99 && qte_input < 1000) {
        $("#qteProd").css("width", "60px");
    } else if (qte_input > 999) {
        $("#qteProd").css("width", "70px");
    } else if (qte_input < 10) {
        $("#qteProd").css("width", "40px");
    }
}

// Satellite: CADEAU D'ENTREPRISE

// Contacter

$("#cde_contact").on("click", function() {
    $(".cadeau_entreprise #cde_contact_popup").css("display", "block");
    $(".cadeau_entreprise .shad").css("display", "block");
})

// Formulaire

var dmd_devis = $(".cadeau_entreprise .dmd_devis")

dmd_devis.on("click", function() {
    $(".cadeau_entreprise #dmd_devis_form").css("display", "block");
    $(".cadeau_entreprise .shad").css("display", "block");
})

$(".cadeau_entreprise .shad").on("click", function() {
    $(".cadeau_entreprise .shad").css("display", "none");
    $( ".cadeau_entreprise #cde_contact_popup" ).css( "display", "none" );
    $(".cadeau_entreprise #dmd_devis_form").css("display", "none");
})

/* AUTO-TOGGLE ON CONTACT PAGE START */
$(function () {
    var get_param = getUrlParams('theme');

    if (get_param != '' && Object.prototype.toString.call(get_param) == '[object String]') {
        get_param = get_param.split(',');
        $('.theme-id-' + get_param[0]).trigger("click");
        $('.faq_theme_' + get_param[0] + ' .faq_question_' + get_param[1]).trigger("click");
        setTimeout(function () {
            $('html, body').animate({
                scrollTop: ($('.faq_theme_' + get_param[0] + ' .faq_question_' + get_param[1]).offset().top - $('nav.mainMenu').height() - 30)
            }, 300);
        }, 300);
    }

    // Permet l'animation de scroll vers la ancre, pour les bouton "nous contacter" et "voir nos catalogue" 
    if ($('.cadeau_entreprise').length) {
            $(document).on("click", ".button", btnLoaderTrigger);
            $('a[href^="#"]').on('click',function (e) {
                e.preventDefault();
                var target = this.hash,
                $target = $(target);
                $('html, body').animate({'scrollTop': $target.offset().top - 200}, 900, 'swing'); // ajout de 200px pour eviter que le block fixed au menu cache le title
            });
    }

    setTimeout(function() {
        if ($('#lightbox_preco').length) {
            $("#shad").click(function() { 
                process_type_preco();
            });
        }
    }, 800);

    setTimeout(function() {
        if ($('html').hasClass('tabletwide')) {
            // Specific case for tablet only
            $('.container_menu_items a').each(function(index) {
                if (!$(this).hasClass('ss_cat_name')) {
                    $(this).attr('href', 'javascript:;');
                }
            });
        }
    }, 3000);
    
    $('.misc_cookiesinfo .content_sat .cookieShowLightbox').click(function() {
        $('.misc_cookiesinfo #cookiesLightbox').addClass('displayCookies');
        if($('.misc_cookiesinfo #cookiesLightbox').has('.hideCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('hideCookies');
        }
        $('.misc_cookiesinfo #cookiesLightbox .w-form-line.w-submit button.w-loader').addClass('hideLoader');
    });

    $('.misc_cookiesinfo #js_cookie_refuse, .misc_cookiesinfo #js_cookie_accept').click(function() {
        if($('.misc_cookiesinfo #cookiesLightbox').has('.displayCookies')) {
            $('.misc_cookiesinfo #cookiesLightbox').removeClass('displayCookies');
            $('.misc_cookiesinfo #cookiesLightbox').addClass('hideCookies');
        }
    });
});

function getUrlParams(prop) {
    var params = {};
    var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
    var definitions = search.split('&');

    definitions.forEach(function (val, key) {
        var parts = val.split('=', 2);
        params[parts[0]] = parts[1];
    });

    return (prop && prop in params) ? params[prop] : params;
}

/**
 * @description Used to switch lang and site with the pop-up
 *
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2022-11-04
 *
 * @return  void
 */
function switchLocale()
{
    var site = ($('#switch_currency').length) ? $('#switch_currency').val() : '1';
    var lang = ($('#switch_lang').length) ? $('#switch_lang').val() : 'fr';

    // we set the new cookie
    createCookie('cookieDispatch', site + '-' + lang, 395)

    // we retrieve the {fr/en/nl} from the current URL
    var url = window.location.pathname.substring(3);

    // if we choose the same site and lang
    if (site_id == site && lang_iso == lang) {
        closeMultiShad('popup_dispatch');
        shadCookie.addClass("actif");
        cookies_bloc_lightbox.removeClass("cache");
        cookies_bloc_lightbox.addClass("notAccepted");
    } else {
        changeLocale(lang, url, site);
    }
}

function changeDevice() {
    $('.price_devise').text(siteCurrency.symbol_right);
}



/**
 * @description Changing flag lang depending on the choice
 *
 * @author  Rémy P  <rp@wshop.com>
 * @since   2022-11-07
 *
 * @return  void
 */
function updateLang() {
    if ($('#switch_lang').length) {
        var selectedLang = $('#switch_lang').val();
        if (selectedLang.length) {
            $('#switch_lang').css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + selectedLang + '.svg) no-repeat 1rem center');
        }
    }
}

/**
 * @description Changing flag currency depending on the choice
 *
 * @author  Rémy P  <rp@wshop.com>
 * @since   2022-11-07
 *
 * @return  void
 */
function updateCurrency() {
    var selectCurrency = $('#switch_currency');
    var selectedCurrency = selectCurrency.val();
    var selectLang = $('#switch_lang');
    var disableSelectLang = false;
    var defaultCurrency = selectedCurrency;
    var defaultLang = selectLang.val();

    switch (selectedCurrency) {
        case '1':
            defaultCurrency = 'ue';
            defaultLang = 'fr';
            disableSelectLang = false;
            // we show the arrow
            $('.popup_dispatch_row_2 .popup_dispatch_arrow').show();
            break;

        case '2':
            defaultCurrency = defaultLang = 'en';
            disableSelectLang = true;
            // we show the arrow
            $('.popup_dispatch_row_2 .popup_dispatch_arrow').hide();
            break;
    }

    selectCurrency.css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + defaultCurrency + '.svg) no-repeat 1rem center');
    selectLang.css('background', 'transparent url(' + path_relative_root.substring(0, (path_relative_root.length - 3)) + 'svg/countries/' + defaultLang + '.svg) no-repeat 1rem center');
    selectLang.prop('disabled', disableSelectLang);
    selectLang.val(defaultLang);

    // switch lang
    if ($('#switch_lang').length) {
        $('#switch_lang option').each(function(k, v) {
            if ($(v).hasClass('lang_' + selectedCurrency)) {
                $(v).show();
            } else {
                $(v).hide();
            }
        });
    }
}

/**
 * @description Handle the display of the dispatch pop-up
 *
 * @author  Stefane DR  <sdr@wshop.com>
 *
 * @return  void
 */
function handleDispatchPopup()
{
    $(document).ajaxComplete(function(event, request, settings) {
        if (settings.url && settings.url.includes('ajax_check_cookie') && !document.cookie.match(/^(.*;)?\s*cookieDispatch\s*=\s*[^;]+(.*)?$/)) {
            // we hide the cookie bottom bar
            shadCookie.removeClass("actif");
            cookies_bloc_lightbox.addClass("cache");
            cookies_bloc_lightbox.removeClass("notAccepted");
            // we show the dispatch pop-up
            openMultiShad('popup_dispatch');
        }
    });
}

// Close rollover panier
function closeRolloverPanier() {
    if ($( '#cart_top' ).hasClass('hover')) {
        $( '#cart_top' ).removeClass( 'hover' );
        $( '#shad_menu' ).removeClass( 'actif' );
    }
}

$.fn.selectChangeAdr = function() {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr("id");
    var data = {};
    var $changeContainer = $(
        ".wrapper_form_adresse",
        $obj.closest(".adresse_change")
    );

    data.act = "ajout";
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = "ajax";

    if (obj_val != "") {
        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = "modif";
        }

        if ($changeContainer.is(".show")) {
            $changeContainer
                .removeClass("show")
                .slideUp()
                .html("");
        }

        $.ajax({
            type: "get",
            data: data,
            url: path_relative_root + create_link('order_delivery_ajax'),
            success: function(response) {
                var $responce = $(response);
                var min_form = $(".min_form", $responce).html();
                var $form = $("#adresseForm", $responce);


                $changeContainer
                    .html($form.html(min_form))
                    .css({display: "none"})
                    .slideDown();

                $(".styled").new_styling_form({
                    type_class_sufix: "",
                    localName_new_elem: "span",
                });

                if (!$changeContainer.is(".show")) {
                    $changeContainer.addClass("show").slideDown();
                }

                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.error(e);
                }

                if ($("#autocomplete_addr").length > 0) {
                    initialize_google_place('delivery');
                }

                $(".type_adresse", ".choix_type_adresse.delivery").on(
                    "click",
                    function() {
                        $(this).show_adresse_title(
                            $(".type_adresse", ".choix_type_adresse.delivery"),
                            "#adresseTitre"
                        );
                    }
                );

                var country_select = "";

                if (typeof $("#pays").val() != "undefined") {
                    country_select = $("#pays")
                        .find(":selected")
                        .data("role")
                        .toLowerCase();

                    $("#pays").change(function() {
                        if (
                            typeof $(this)
                                .find(":selected")
                                .data("role") != "undefined"
                        ) {
                            country_select = $(this)
                                .find(":selected")
                                .data("role")
                                .toLowerCase();
                        }

                        if (typeof $("#telephone").val() != "undefined") {
                            $("#telephone").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }

                        if (typeof $("#mobile").val() != "undefined") {
                            $("#mobile").intlTelInput(
                                "setCountry",
                                country_select
                            );
                        }
                    });
                } else {
                    country_select = "fr";
                }
            },
        });
    }
};

$(document).ready(function() {
    // toggle page CSM
    $(function() {
        $(".dropdown_cms").click( function () {
            $(this).next('ul.menu_cms').slideToggle();
        });
    });

    $('.cms_menu_item.filter .bgSprite').click(function() {
        $('.cms_menu_item.filter').toggleClass("open");
    });
});