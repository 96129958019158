$.datepicker.regional['fr'] = {
    clearText: 'Effacer',
    clearStatus: '',
    closeText: 'Fermer',
    closeStatus: 'Fermer sans modifier',
    prevText: '<Préc',
    prevStatus: 'Voir le mois précédent',
    nextText: 'Suiv>',
    nextStatus: 'Voir le mois suivant',
    currentText: 'Courant',
    currentStatus: 'Voir le mois courant',
    monthNames: ['Janvier','Février','Mars','Avril','Mai','Juin',
    'Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
    monthNamesShort: ['Jan','Fév','Mar','Avr','Mai','Jun',
    'Jul','Aoû','Sep','Oct','Nov','Déc'],
    monthStatus: 'Voir un autre mois',
    yearStatus: 'Voir un autre année',
    weekHeader: 'Sm',
    weekStatus: '',
    dayNames: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
    dayNamesShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
    dayNamesMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
    dayStatus: 'Utiliser DD comme premier jour de la semaine',
    dateStatus: 'Choisir le DD, MM d',
    dateFormat: 'dd/mm/yy',
    firstDay: 0,
    initStatus: 'Choisir la date',
    isRTL: false,
};

// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load==false) ? false : true;

/*******************************************************************************************************/
/* CUSTOM FUNCTIONS
/*******************************************************************************************************/
function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad):'#shad' ;

    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return false;
    }
}

//define addMessage function here to avoid console error
function addMessage() {

    var formMotPerso = $('#mot_personnalise');

    if ($("#has_perso_word").prop('checked') === true) {
        formMotPerso.slideDown("slow");
        $('input[name=add_mot_perso]').val('ok');
    } else {
        formMotPerso.slideUp("speed");
        $('input[name=add_mot_perso]').val('no');
    }

}

function triggerRollover(source) {
    source.mouseover(function() {
        $('.right.holderRollovers').addClass('onRollover');
    }).mouseout(function() {
        $('.right.holderRollovers').removeClass('onRollover');
    })
}

//LOADING CTA
$('#btn_cmd_valid, .w-submit-button, .loader_scroll').click(function() {
    function greet() {
        $('.form_submit').removeClass('loading');
    }
    setTimeout(greet, 1000);
});

function fadeHeaderElems(from, action) {
    // Only if header background is transparent == On page top
    if (!$('#site_head_wrap.sticky').length) {
        if (action == 'add') {
            if (from == "right") {
                $('.block_top_links.left, .main_menu_wrapper, .block_top_links.logo').addClass('goTransparent');
            } else if (from == "left") {
                $('.block_top_links.right, .main_menu_wrapper, .block_top_links.logo').addClass('goTransparent');
            }
        } else {
            $('.goTransparent').removeClass('goTransparent');
        }
    }
}

function changeCountry() {
    var str = window.location.href;
    var url = new URL(str);
    var search_params = new URLSearchParams(url.search);
    if (search_params.has('country_id')) {
        var countryId = search_params.get('country_id');
    }

    if (countryId !== undefined) {
        $('#search_pays').on('change', function() {
            let selectedCountry = $(this).val();
            if (selectedCountry && selectedCountry != countryId) {
                countryId = selectedCountry;
            }
            $(this).val(countryId);
            var country = $(this).find("option[value='" + countryId + "']").text();
            $('.w-dropdown .w-value').html(country);
            $('.w-dropdown').addClass('w-nonempty w-focused');
        });

        $('#search_pays').trigger('change');
    }
}

//SWITCH LANG
function showLayer() {
    if ($('#dropdown-content').hasClass('show')) {
        $('#dropdown-content').removeClass('show');
    } else {
        $('#dropdown-content').addClass('show');
    }
}

//Demande client : fonction pour permettre l'enregistrement du message personalisé en session
function keepGiftMessage() {
    setTimeout(function(){
        if ($("#has_perso_word").prop('checked') === false) {
            $("#has_perso_word").prop('checked');
            $('input[name=add_mot_perso]').val('ok');
        }
        $('#mot_personnalise').slideDown("slow");

            /* keep gift message value in session storage */
            // Get the text area element
            let $textArea = $("#mot_personnalise_texte");

            // Check if data is already stored in local storage
            if (sessionStorage.getItem("textAreaData")) {
            // If data is stored, retrieve it and set it as the value of the text area
            $textArea.val(sessionStorage.getItem("textAreaData"));
            }

            // Listen for changes to the text area
            $textArea.on("input", function() {
            // Store the new data in local storage
            sessionStorage.setItem("textAreaData", $textArea.val());
            });
    }, 300);
}
//permet le déclenchement de la fonction de sauvegarde du message perso à l'ajout produit du panier à la wishlist
$(window).on("wishToBasket_complete", function (data) {
    keepGiftMessage();
})

/*******************************************************************************************************/
/* HEADER STICKY SCROLL
/*******************************************************************************************************/

var header = document.getElementById("site_head_wrap");

function onWinScroll() {
    var scrollTop = $(document).scrollTop();

    if (header.classList.contains("sticky")) {

        if (scrollTop < 100) {
            header.classList.remove("sticky");
        }
    } else {
        if (scrollTop >= 100) {
            header.classList.add("sticky");
        }
    }
}

$(window).on("scroll", onWinScroll);
onWinScroll();

// ONLOAD
$(window).on('load', function() {
    // ONCHANGE SELECT COUNTRY STORE LOCATOR
    if ($('.store_locator').length) {
        changeCountry();
    }
});

$(function() {        
    /* keep gift message value in session storage */
    // Get the text area element
    let $textArea = $("#mot_personnalise_texte");
    
    // Check if data is already stored in local storage
    if (sessionStorage.getItem("textAreaData")) {
      // If data is stored, retrieve it and set it as the value of the text area
      $textArea.val(sessionStorage.getItem("textAreaData"));
    }
    
    // Listen for changes to the text area
    $textArea.on("input", function() {
      // Store the new data in local storage
      sessionStorage.setItem("textAreaData", $textArea.val());
    });

    if ($('.button').hasClass('find_retailer')) {
        $('#btn_add_cart').remove();
    }

    // /***** BURGER MENU *****/
    $('.burger_menu_container').click(function() {
        $('.burger_menu').toggleClass('is_clicked');
        $('.bot_header_wrapper').toggleClass('is_open');
        $('#shad_menu').addClass('actif');
        $('#header_mention_id, .header').toggleClass('nav_burger_open');
        $('.burger_menu_close').addClass('visible');
        $('.ss_menu_categ_wrapper').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    });

    $('.burger_menu_close').click(function() {
        $('.burger_menu').toggleClass('is_clicked');
        $('.bot_header_wrapper').toggleClass('is_open');
        $('#shad_menu').removeClass('actif');
        $('#header_mention_id, .header').toggleClass('nav_burger_open');
        $(this).removeClass('visible');
    });

    var menu_timeout = setTimeout(null);

    $(".menu_itm_hover").on("mouseenter", function(evt) {
        var submenu = $(".submenu_wrapper", this);
        evt.preventDefault();
        clearTimeout(menu_timeout);

        var linkToClick = $(this).find('.has_submenu a');

        //Si on est en résolution supérieure à 1201, on gère l'effet de hover sur les items du menu
        if (window.matchMedia("(min-width: 1201px)").matches) {
            submenu.addClass('menu_visible');
        //Sinon, on gère la navigation dans le menu burger au clic sur les items
        } else {
            var retourToIndex = $(this).find('.retour_low_res');

            linkToClick.on('click', function() {
                $(this).closest('.main_menu_itm').addClass('next_menu');
            });
            retourToIndex.on('click', function() {
                $(this).closest('.main_menu_itm').removeClass('next_menu');
            });
        }
    });

    /*******************************************************************************************************/
    /* Preheader swiper init & Close preheader
    /*******************************************************************************************************/
    if ($('.promo_swiper').length) {
        $("body").addClass("bandeauDelivery");
        if ($('.promo_swiper .swiper-slide').length > 1) {
            var preheader_swiper = new Swiper('.promo_swiper', {
                autoplay: true,
                slidesPerView : 1,
                loop: true,
                speed: 2000
            });
        }
    }

    // Close preheader
    var headerDelivery = $(".header_mention");
    $(".close_delais").on("click", function() {
        $("body").removeClass("bandeauDelivery");
        $("#site_head_wrap").removeClass("bandeau_on");
        headerDelivery.slideUp(500, function() {
            headerDelivery.remove();
        });
    });

    /*******************************************************************************************************/
    /* SWIPER HP
    /*******************************************************************************************************/

    // SWIPER COVER
    if ($('.coverHomeSwiper').length && ($('.coverHomeSwiper .swiper-slide').length > 1)) {
        var preheader_swiper = new Swiper('.coverHomeSwiper', {
            autoplay: {
                delay: 5000,
            },
            slidesPerView: 1,
            loop: true,
            speed: 2000,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            }
        })
    }

    // SHOW WRAP SEARCH
    $('#trigger_recherche').on("click touch", function() {
        if ($('.wrap_search_field').hasClass('actif')) {
            $('#shad_menu').removeClass('actif search_active');
            $('.wrap_search_field.actif').removeClass("actif");
            $('.main_menu_itm').parent().removeClass("hover");
        } else {
            $('.wrap_search_field').addClass("actif");
            $('#shad_menu').addClass('actif search_active');
        }
    });

    $('#shad_menu').on('click', function() {
        if ($('.wrap_search_field').hasClass('actif')) {
            $('#shad_menu').removeClass('actif search_active');
            $('.wrap_search_field.actif').removeClass("actif");
        }
    });

    /* LANG SWITCH*/
    $('.wrap_lang').on({
        mouseenter: function (e) {
            $(this).parent('.left').addClass('onRollover');
            $(this).find('.lang_switch').addClass('hovering');
            $('#shad_menu').addClass('actif');
            fadeHeaderElems('left', 'add');
            //check if layer search product is actif
            if ($('.wrap_search_field').hasClass('actif')) {
                e.stopPropagation();
                $('.wrap_search_field').removeClass('actif');
            }

        },
        mouseleave: function (e) {
            $(this).parent('.left').removeClass('onRollover');
            $(this).find('.lang_switch').removeClass('hovering');
            $('#shad_menu').removeClass('actif');
            fadeHeaderElems();
            if ($('.wrap_search_field').hasClass('actif')) {
                e.stopPropagation();
            }
        },
    });

    /* LAYER CUSTOMER SERVICE*/
    $('.customer_service').on({
        mouseenter: function (e) {
            $(this).parent('.left').addClass('onRollover');
            $(this).find('.top_item_cta').addClass('hovering');
            $('#shad_menu').addClass('actif');
            fadeHeaderElems('left', 'add');
            //check if layer search product is actif
            if ($('.wrap_search_field').hasClass('actif')) {
                e.stopPropagation();
                $('.wrap_search_field').removeClass('actif');
            }
        },
        mouseleave: function (e) {
            $(this).parent('.left').removeClass('onRollover');
            $(this).find('.top_item_cta').removeClass('hovering');
            $('#shad_menu').removeClass('actif');
            fadeHeaderElems();
            if ($('.wrap_search_field').hasClass('actif')) {
                e.stopPropagation();
            }
        },
    });

        /**
        * TOP WISHLIST ROLLOVER
        */
        var topWish = $("#wishlist_top");

    topWish.mouseover(function(e) {
         //check if layer search product is actif
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
            $('.wrap_search_field').removeClass('actif');
        }
        $('#shad_menu').addClass('actif');
    }).mouseout(function() {
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
        } else {
            $('#shad_menu').removeClass('actif');
        }
    });


    /**
    * TOP MyAccountIsLogged ROLLOVER
    */
    var topMyAccountIsLogged = $(".my_account.is_logged");

    topMyAccountIsLogged.mouseover(function(e) {
        //check if layer search product is actif
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
            $('.wrap_search_field').removeClass('actif');
        }
    }).mouseout(function() {
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
        }
    });

        /**
        * TOP CART ROLLOVER
        */
        var topCart = $("#cart_top");

    topCart.mouseover(function(e) {
        //check if layer search product is actif
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
            $('.wrap_search_field').removeClass('actif');
        }
    }).mouseout(function() {
        if ($('.wrap_search_field').hasClass('actif')) {
            e.stopPropagation();
        }
    });

    // NAV ENTREE RESOLUTION > 1200 px
    if ($(window).width() > 1200) {
        //navigation
        $('.main_menu_itm').on({
            mouseenter: function(e) {
                if ($('.wrap_search_field').hasClass('actif')) {
                    e.stopPropagation();
                    setTimeout(function() {
                        $('.wrap_search_field').css('transition','none');
                        $('.wrap_search_field').removeClass('actif');
                    }, 500);
                }
                $(this).parent().addClass('hover');
                triggerShad('menu', 'add');

            },
            mouseleave: function(e) {
                $(this).parent().removeClass('hover');
                triggerShad('menu', 'remove');
                $('.wrap_search_field').css('transition','transform, opacity .2s ease-in-out');

            },
        });
    }

    // ROLLOVER SCROLLBARS
    if ($('#cart_list').length) {
        if ($('#cart_list .product-link').length > 2) {
            $("#cart_list").overlayScrollbars({});
        }
    }

    if ($('#show_top_cart').length) {
        $('#show_top_cart .holder_scrollable').overlayScrollbars({});
    }

    if ($('.ss_menu_categ_wrapper').length) {
        $('.ss_menu_categ_wrapper .ss_cat_wrapper').each(function() {
            $(this).overlayScrollbars({
                overflowBehavior : {
                    x : "scroll",
                    y : "hidden"
                },
            });
        });
    }

    // smallscreens scrollbars
    if ($('#wishlist_not_logged').length) {
        $('#wishlist_not_logged').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    if ($('#show_top_wish #cart_list').length) {
        $('#show_top_wish #cart_list').overlayScrollbars({
            scrollbars: {
                autoHide: "leave",
                autoHideDelay: 0,
            }
        });
    }

    // FAQ
    $('.theme').on('click', function() {
        $('.back_opacity').removeClass("actif");
        $(this).children('.faq_background_image').children().addClass("actif");
    })

    /*******************************************************************************************************/
    /* RAYON
    /*******************************************************************************************************/
    // check ProductInWishlist
    if ($('.imgWrapper').length > 0) {
        $(document).on("mouseenter", '.imgWrapper', function () {
            var produit_id = $(this).find("form input[name='produit_id']").val();

            if (produit_id > 0) {
                checkProductInWishlist(produit_id);
            }

        });
    }

    /* Hide Border Color last wrap Rayon & Search */
    if ($('.category .filters_container.bottom').length) {
        $('.filters_container.bottom').find('.form_submit').prev().children().css('border','0')
    }

    // FILTERS RAYON
    if ($('#rayon_sticky_block').length) {
        if ($('.wrapper_scrollable').length) {
            $('.wrapper_scrollable').overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        }
    }

    //STATE MOUSEENTER MOUSELEAVE CLICK
    $(".category .customChecker, .category .form_itm.check").each(function() {
        $(this).mouseenter(function () {
            $(this).addClass('is_checked');
        });
        $(this).mouseleave(function () {
            $(this).removeClass('is_checked');
        });
        var input = $(this).find('input');
        var label = $(this).find('label');
        input.click(function () {
            if ($(this).next().hasClass('is_checked')) {
                $(this).next().removeClass('is_checked');
            } else {
                $(this).next().addClass('is_checked');
            }
        });
        label.click(function () {
            if ($(this).prev().hasClass('is_checked')) {
                $(this).prev().removeClass('is_checked');
            } else {
                $(this).prev().addClass('is_checked');
            }
        });
    });

    /* ALERTE STOCK STOP KEYPRESS ENTER*/
    $("input[name='mail_alerte_stock']").each(function() {
        $(this).keypress(function (e) {
            if (e.keyCode  == '13') {
                e.preventDefault();
            }
        });
    });

    /* AJAX PAGE RECHERCHE COUNT */
    if ($('body.category').length && $('body.category').hasClass('search_in')) {
        $(document).ajaxComplete(function() {
            // compteur attributs
            $('.wrapper_filtre input').each(function() {
                $(this).click(function() {
                    countItemFilter(this,this.attr('name'));
                })
            });
        });
    }

    /* STORE LOCATOR  */
    if ($('.satellite_store_details').length) {
         // Get current day
        const d = new Date();
        let day = d.getDay();
        if (day === 0) {
            day = 7; // hightlight dimanche
        }
        // hightlight current day
        $('.time_table li:nth-child('+ day +')').addClass('current');
    }

    /* FIX LOADING PAGE CONNEXION  */
    if ($('.login_wrapper').length) {
        $('.wrapper_bandeau_connexion img').addClass('fade_in');
        setTimeout(function() {
            $('.login_wrapper').addClass('fade_in');
        }, 100);
    }

    /*******************************************************************************************************/
    /* FICHE PRODUIT & RAYON
    /*******************************************************************************************************/


    /**
     * Clone price in express buy BTN (Gondole + Ray + Assoc)
     */
    if ($('.wrapperProdVertical').length) {

        $('.wrapperProdVertical').each(function () {
            var productPrice = $(this).find('.item_price');
            var productButton = $(this).find('.w-submit-button');
            var is_precommande = $(this).find('.w-submit-button').attr('data-is-preco');
            var text_precommande = $(this).find('.w-submit-button span');

            productPrice.insertAfter($(this).find('.item_subtitle'));

            if (is_precommande !== undefined ) {
                text_precommande.text(Translator.translate('add_basket_preco'));
            } else {
                productButton.find('.item_price').remove();
                productPrice.clone().appendTo(productButton);
            }
        });
    }

    // Page cadeau d'entreprise
    if ($('.cadeau_entreprise').length) {
        // Affichage de la lightbox des contacts
        if ($( '#lightbox_nos_contacts').length && $( '.cta_nos_contacts' ).length) {
            $(window).on( "scroll", function () {
                var headerH = $( '#site_head_wrap' ).height();
                if ( $( '#site_head_wrap' ).hasClass( 'sticky' )) {
                    $( '.cta_nos_contacts' ).css( 'top', (headerH )  + 'px');
                } else {
                    $( '.cta_nos_contacts' ).css( 'top', '-100%');
                }
            });
        }
    }

    // Page contact
    if ($( 'body' ).hasClass('contact_faq') && $( '.w-input-element[name="form_service"]' ).length) {
        var sService = $( '.w-input-element[name="form_service"]' );
        var fNumCmd = $( '.w-contact-form #divContactNumCommande' );
        sService.on( 'change', function () {
            if ($( this ).val() == 1) {
                fNumCmd.show();
            } else {
                fNumCmd.hide();
            }
        });
    }

    /**
     * Switch delivery infos on payment page
     */
    if ($('body.checkout').length) {
        $('.wrapper_payment').insertBefore('#cart_total');
    }

   // positionnement upselling
    if ($('.upselling.price').attr( "data-upselling")===0) {
        $('.upselling.price').hide;
    }

    handleDispatchPopup();

    // Swiper page ADV cover

    $(".cms-module.cover-module .coverSwiper").each(function() {
        // Find specific nav buttons in case more than one swiper is on the page
        var thisPagination = $(this).parent().find('.swiper-pagination');

        var adv_cover_swiper = new Swiper($(this), {
            paginationClickable: true,
            pagination: thisPagination,
            spaceBetween: 20,
            slidesPerView: "auto",
            freeMode: true,
        })
    });

    $(".cms-module.cover-module .coverSwiper").each(function() {
        // Find specific nav buttons in case more than one swiper is on the page
        var thisNext = $(this).parent().find('.swiper-cover-button-next')[0];
        var thisPrev = $(this).parent().find('.swiper-cover-button-prev')[0];

        var adv_cover_swiper = new Swiper(this, {
            watchOverflow: true,
            spaceBetween: 20,
            slidesPerView: 2,
            navigation: {
                nextEl: thisNext,
                prevEl: thisPrev,
            },
            breakpoints: {
                700: {
                slidesPerView: 3,
                },
                1024: {
                slidesPerView: 4,
                },
            },
        })
    });
});

/**
 * Function called in _app when cart items are updated after an action (move to wishlist, change qte etc...)
 */
function updateCartElements() {
    $('.cart_product_line').each(function () {
        var formQteWrapper = $(this).find('.cart_product_sizecol.quantity');
        var formQte = $(this).find('form.qte_selector');
        formQteWrapper.append(formQte);
    });

    keepButtonGiftForProductOnly();

    // Check Warning msg display on Cart Page
    // Excluded product case
    var btn = $("#formPanier #btn_cmd_valid");
    if (document.querySelector('.cart_product_excluded') != null) {
        // Disable Cart Validation button
        btn.attr("disabled", "disabled");
    } else {
        btn.removeAttr("disabled");
        $("#panier_custom_message").hide();
    }

    keepGiftMessage();
}


if ($('.cart.step_1').length) {
    updateCartElements();
    // positionnement upselling
    upsellingPosition();
}

$('#formPanier').submit(function (evt) {
    // check delivery date
    if ($('#order_delivery_date').length) {
        let order_delivery_date = $('#order_delivery_date').val();
        if (!order_delivery_date) {
            evt.preventDefault();
            $('.bloc_livraison.active .order_delivery_date').css('border', 'red 1px solid');
            $('.bloc_livraison.active .block-delivery-date .err_delivery_date').html(Translator.translate('err_delivery_date')).show();
            return false;
        } else {
            let verifDate = verifyDateIntegrity(order_delivery_date);
            if (!verifDate) {
                evt.preventDefault();
                $('.bloc_livraison.active .order_delivery_date').css('border', 'red 1px solid');
                $('.bloc_livraison.active .block-delivery-date .err_delivery_date').html(Translator.translate('err_delivery_date')).show();
                return false;
            }
        }
    }
});

$('.order_delivery_date').click(function() {
    $('.bloc_livraison.active .order_delivery_date').css('border', 'none');
    $('.bloc_livraison.active .block-delivery-date .err_delivery_date').empty().hide();
});

// positionnement uselling
function upsellingPosition () {
    if ($('.upselling.price:visible')) {
        $('#cart_total').addClass('uspselling_visible');
    }

    if ($('.upselling.price').attr( "data-upselling")== 0) {
        $('.upselling.price').addClass('uspselling_hide');
        $('#cart_total').removeClass('uspselling_visible');
    }
}

$(function() {   

    if ($('.sameBillAddress').length > 0) { 
        if ($('.w-newuser-form legend').first().text() == Translator.translate('votre_adresse_facturation')) {
            $('.sameBillAddress').hide();
        }
    }

    $('.modlink.modify').on('click', function (e) {
        let $targetEvent = $('.show_modif_info');
        let bloc_liv_hidden = $('.hidden_elem_liv', $targetEvent.closest('.bloc_livraison')); 

        if (typeof bloc_liv_hidden != 'undefined' && bloc_liv_hidden != null && bloc_liv_hidden.length > 0) { // SW-11040 Case when bloc livraison does not show for some postal codes
            e.preventDefault();
    
            let bloc_liv = $targetEvent.closest('.bloc_livraison');
            if (bloc_liv_hidden.is(':visible')) {
                bloc_liv_hidden.slideUp();
            } else {
                if (!bloc_liv.is('.disabled')) {
    
                    bloc_liv_hidden.slideDown('.hidden_elem_liv', '', function () {
    
                        let $changeAdr = $('.select_change_adr', bloc_liv);
    
                        $changeAdr.attr('id', $('.active .choix_delivery').data('id-transporteur'));
                    });
                }
            }
        }
    });

    setTimeout(function() {
        $('.w-address-form').css('opacity', 1);
    }, 500);


});

// Gift Card
let giftCardHelp = "<h3 class='text-besoin-aide is-hidden'>" + Translator.translate('giftcard_offer') + "</h3>";
$('#showAmount.btn_container.return').after(giftCardHelp);

function toggleGiftCardBalance() {
    var connectBtn = $(".btn_container");

    if (connectBtn.hasClass("notLogged")) {
        location.href=path_relative_root+create_link('order_identification')+'?from=giftcard';
    } else {
        $(".pageGiftCard__main--infos").toggleClass('is-hidden');
        $(".headerTitles").toggleClass('is-hidden');
        $(".pageGiftCard__main--amountinfos").toggleClass('is-hidden');
        $(".showAmountBtn").toggleClass('is-hidden');
        $(".text-besoin-aide").toggleClass('is-hidden');
    }
};

$(document).ready(function() {
    var $asideImg = $(".pageGiftCard__asideImg");
    $asideImg.prependTo(".pageGiftCard");

    var $headerTitle = $(".pageGiftCard__main.pageGiftCard__main--amountinfos");
    $headerTitle.insertAfter(".headerTitles");

    var $infosDiv = $(".pageGiftCard__main.pageGiftCard__main--infos");
    var $headerTitlesDiv = $(".headerTitles");
    $infosDiv.insertAfter($headerTitlesDiv);

    $('.container__input-code .w-submit').on('click', function(){
        setTimeout(function() {
            if ($('#formGiftCard .w-input-note').hasClass('w-input-error')) {
                $('#formGiftCard .w-input-element').addClass('w-input-error');
            } else {
                $('#formGiftCard .w-input-element').removeClass('w-input-error');
            }
        }, 500);
    });
    
    setTimeout(function() {
        $(".pageGiftCard").animate({opacity: 1}, 500);
    }, 200);

    $('#s_msgPerso').on('input', function() {
        var maxLength = 235;
        var currentLength = $(this).val().length;

        if (currentLength >= maxLength) {
            $(this).addClass('error-border');
        } else {
            $(this).removeClass('error-border');
        }
    });

    // Find the anchor element with the class "cgvLink" and add the target="_blank" attribute
    $(".giftcard_index .cgvLink").attr("target", "_blank");

    //Message erreur et success
    $('#mes_err_contact').insertAfter('.accountCreate');
    $('#mes_err_contact').insertAfter('.w-has-tel');
    if ($('.wrapper_content_mes_infos').length && $('.wrapper_content_mes_infos .update_success').length) {
        $('.wrapper_content_mes_infos .update_success').css('opacity','0').insertBefore('.wrapper_content_mes_infos .w-form-line:last-child()').hide();
    
        setTimeout(function() {
            $('.wrapper_content_mes_infos .update_success').css('opacity','1').insertBefore('.wrapper_content_mes_infos .w-form-line:last-child()').show();
        }, 1000);
    }
});

function keepButtonGiftForProductOnly() {
    $('#cart_total .bill_line.giftCmd').hide();

    $('.basket_contents .product_line_basket').each( function () {
        let productId = $(this).attr('data-product-id');

        if (productId > 0) {
            $('#cart_total .bill_line.giftCmd').show();
        }
    });
}