var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

//declare function font-weight customization for titles, product names
function fontWeightObject(element, sep) {
    var sep;
    var element;
    var separator = element.html().split(sep);
    element.html('<span>' + separator.shift() + ' </span>' + separator.join(' '));
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Checks any form
function ajax_checkAllForm(
    tabError,
    blockMenuTopConnexionInfobulle,
    path_relative_root
) {
    if (checkAllForm(tabError, blockMenuTopConnexionInfobulle)) {
        if (tabError[0][1] && tabError[0][1] == "clientMailAuthTop") {
            $.ajax({
                url: path_relative_root + create_link('ajax_login_allowed'),
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: true,
                },
                success: function(res) {
                    var url, index_deb, index_fin, provenance;

                    if (res[0] !== "0") {
                        url = window.location.pathname;
                        index_deb = url.lastIndexOf("/");
                        index_fin = url.lastIndexOf(".");
                        provenance = url.substring(index_deb + 1, index_fin);

                        if (provenance === "connexion") {
                            window.location.replace(
                                path_relative_root + create_link("client_index")
                            );
                        } else if (provenance === "identification") {
                            window.location.replace(
                                path_relative_root + "livraison-kiala.php"
                            );
                        } else {
                            window.location.reload();
                        }
                    } else {
                        document.getElementById("AuthTopErr").style.display =
                            "block";

                        $(".loader").css("display", "none");
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLostTop") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + create_link('ajax_email_exist'),
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                "ajax_send_new_password_link.php",
                            type: "get",
                            data: {mail: mail},
                            succes: function() {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdTopErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdTopSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();
                            },
                        });
                    } else {
                        document.getElementById("lostpwdTopErr").style.display =
                            "block";

                        hideLoader();
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "clientMailAuth") {
            $.ajax({
                url: path_relative_root + create_link('ajax_login_allowed'),
                type: "get",
                data: {
                    mail: document.getElementById(tabError[0][1]).value,
                    mdp: document.getElementById(tabError[1][1]).value,
                    is_top: false,
                },
                success: function(res) {
                    hideLoader();

                    if (res == 1) {
                        $("#auth").submit();

                        return true;
                    } else {
                        document.getElementById("AuthErrErr").style.display =
                            "block";

                        return false;
                    }
                },
            });
        } else if (tabError[0][1] && tabError[0][1] == "mailLost") {
            var mail = document.getElementById(tabError[0][1]).value;

            $.ajax({
                url: path_relative_root + create_link('ajax_email_exist'),
                type: "get",
                data: {mail: mail},
                success: function(res) {
                    if (res == 1) {
                        $.ajax({
                            url:
                                path_relative_root +
                                create_link('ajax_send_new_password_link'),
                            type: "get",
                            data: {mail: mail},
                            success: function(res) {
                                show_lightbox(
                                    "popup_confirm_reinitialisation_mdp"
                                );

                                document.getElementById(
                                    "lostpwdErr"
                                ).style.display =
                                    "none";
                                document.getElementById(
                                    "lostpwdSuccesTitle"
                                ).style.visibility =
                                    "hidden";

                                hideLoader();

                                return false;
                            },
                        });
                    } else {
                        document.getElementById("lostpwdErr").style.display =
                            "block";

                        hideLoader();

                        return false;
                    }
                },
            });
        }
    } else {
        $(".loader").css("display", "none");
    }
}

// Ensures this.value is non-empty
// Binds to HTMLInputElement
// Returns true if value is non-empy, false otherwise
function nonEmpty() {
    return !!this.value.trim();
}

// Adds "display" rule to bond element's style with a value of "block"
function show() {
    this.style.display = "block";
}

// Adds "display" rule to bond element's style with a value of "none"
function hide() {
    this.style.display = "none";
}

// Adds leading zeroes to a given value
function zeroPad(nbr, base) {
    var zpad;

    base = parseInt(base) > 1 ? base : 10;
    zpad = nbr < base ? "0" : "";

    return zpad + nbr.toString(base).toUpperCase();
}


// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {
        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'block';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link("order_basket"),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                let dataLayer_object = res.datalayer_object;
                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                // Set event ID for product detail dataLayer
                if (typeof(dataLayer_object) == 'object') {
                    pushIntoDatalayer(dataLayer_object);
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;            
                
                // Check if we got excluded products on changeQte
                var btn = $("#formPanier #btn_cmd_valid");
                if (document.querySelector('.cart_product_excluded') !== null) {
                    // Disable Cart Validation button
                    btn.attr("disabled", "disabled");
                } else {
                    btn.removeAttr("disabled");
                    $("#panier_custom_message").hide();
                }

                var $label_basket_line_qte = Translator.translate('quantity');
                $( "<label>" + $label_basket_line_qte + "</label>" ).insertBefore( ".product_quantity_change .qteWrapper" );

            },
            complete : function() {
                var total = $('#cart_top .cart').attr('data-cart'),
                cart = Translator.translate('mon_panier'),
                text;
                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                };
                $('.cart_main_title.basket').html(cart +' '+ '<span class="nb_products">(' + total + ' ' + text + ')</span>');

                if (total > 2) {
                    $(".cart_product_listing ").overlayScrollbars({});
                }

                updateCartElements();

                // position upselling
                upsellingPosition();
            }
        });
    }
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {

    var form = this;
    var old_id = this.getValue('produit_id');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    if (this.elements.namedItem('qteProd')) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var isCategSuper = this.getValue('isCategSuper') ? this.getValue('isCategSuper') : 0;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        isCategSuper : isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data : data,
        success : function (res) {

            try {

                res = $.parseJSON(res);
            } catch (e) {

                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {

                var datas = res.datas;

                if (data.type_page == 'product') {

                    var $html_main = $(stripCombo(datas.html_main));

                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if ($("#buyNowSwiper").length > 0) {
                            var buyNowSwiper = document.getElementById("buyNowSwiper");

                            if (buyNowSwiper.getElementsByClassName("swiper-slide").length) {

                                buyNowSwiper = new Swiper("#buyNowSwiper", {
                                    sliderPerView: 1,
                                    nextButton: '.swiper-button-next',
                                    prevButton: '.swiper-button-prev'
                                });
                            }
                        }

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }

                    } else {

                        var color = $("#ligne_couleur").html();
                        var contents = $('<div />').html($html_main);

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        var video = $('.wrapper_video', contents);

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        // On scroll en haut de la page dans le cas d'un regroupement
                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }
                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {

                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                json_colisages = res.stock;
                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                var productSwiper = new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

                $('.thumbslide .see_more').on('click', function(e) {
                    $('.thumbslide').removeClass('active');
                    $(this).parents('.thumbslide').addClass('active');
                });

            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete : function () {

            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function () {
                if ($("#jqzoom .swiper-slide").length > 0) {
                    var main_productVisu = new Swiper('#jqzoom', {
                        slidesPerView: 1,
                        navigation: {
                            nextEl: '.mainVisNext',
                            prevEl: '.mainVisPrev',
                        },
                        pagination: {
                            el: ".swiper-pagination",
                        },
                        lazy: true,
                        loadPrevNext: true,
                        loadOnTransitionStart: true
                    });

                    var stillImageProduct = new Swiper('.stillImageProduct', {
                        slidesPerView: 1,
                        noSwiping: true,
                        lazy: true,
                        loadPrevNext: true,
                    });

                    main_productVisu.controller.control = stillImageProduct;
                }

                function zoomImg(slide) {
                    $('#zoom_box_wrapper').css('opacity', 0);
                    window.setTimeout(function () {
                        if (slide) {
                            goto = slide;
                        }

                        goto = goto+1;

                        $('#zoom_box_wrapper').animate({opacity: 1}, 650);

                    }, 350);

                    openMultiShad('zoomBox');
                }

                function updateSrc(path_web, dest) {
                    var tmp_string_large = document.getElementById("img_large").src;
                    tmp_string = tmp_string_large.replace(/medium/, "large");

                    document
                        .getElementById("img_large")
                        .setAttribute(
                            "onclick",
                            "zoomImg('" + tmp_string + "','" + path_web + "')"
                        );
                    document.getElementById("loupe").href =
                        "javascript:zoomImg('" + tmp_string + "','" + path_web + "');";
                    $(".zoomLens").css({backgroundImage: 'url("' + tmp_string_large + '")'});

                    if (dest == "vue") {
                        var i = 0;
                        var trouve = false;
                        while (i < tab_js.length && !trouve) {
                            trouve =
                                tab_js[i][0].substring(tab_js[i][0].lastIndexOf("/") + 1) ==
                                tmp_string.substring(tmp_string.lastIndexOf("/") + 1);
                            i++;
                        }

                        cpt_img = i - 1;
                    }
                }

                function updateZoomedImg(src) {
                    $(".main_vis img", this).attr("src", src);
                }

                // Fin reinitialisation sliders visus
                var more_vis = $('.more_vis');
                var grabber;

                more_vis.each(function () {
                    var jqzoom = $(this).next('#jqzoom');

                    if (jqzoom.length > 0) {
                        $(this).height(jqzoom.height());

                        if (document.getElementById('visual_scroller')) {
                            setTimeout(function () {
                                grabber = new Grabber(document.getElementById('visual_scroller'), true, false);
                            }, 250);
                        }
                    }

                    var zoom_box_wrapper = $(this).next('#zoom_box_wrapper');

                    if (zoom_box_wrapper.length > 0) {
                        $(this).height(zoom_box_wrapper.height());

                        if (document.getElementById('thumbs_scroller')) {
                            setTimeout(function () {
                                grabber = new Grabber(document.getElementById('thumbs_scroller'), true, false);
                            }, 250);
                        }
                    }
                });

                var productImgsWrapper = $(".productImgsWrapper");
                var productDescrWrapper = $(".productDescrWrapper");

                $(document).on("scroll", function () {

                    var scrollTop = $(document).scrollTop();
                    var newTop = (scrollTop - (productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()));

                    if (scrollTop >= productImgsWrapper.innerHeight() - productDescrWrapper.innerHeight()) {
                        productDescrWrapper.css("top", "-" + newTop + "px");
                    } else {
                        productDescrWrapper.css("top", "auto");
                    }
                });

                $(window).on('resize', function () {
                    more_vis.each(function () {
                        var jqzoom = $(this).next('#jqzoom');

                        if (jqzoom.length > 0) {
                            $(this).height(jqzoom.height());
                        }

                        var jqzoom = $(this).next('#zoom_box_wrapper');

                        if (jqzoom.length > 0) {
                            $(this).height(zoom_box_wrapper.height());
                        }

                    });
                });

                $('body').on('mouseenter touchstart','#list_item .item .wrap_rolloverproduit',function() {
                    if ($(".prod_listes.size_list .swiper-slide").length > 1) {
                        var productSwiper = new Swiper($(this).find(".size_list"), {
                            loop: false,
                            slidesPerView: 'auto',
                            navigation: {
                                nextEl: '.productSizeFieldset .swiper-button-next',
                                prevEl: '.productSizeFieldset .swiper-button-prev',
                            },
                            scrollbar: {
                                el: '.swiper-scrollbar',
                            },
                        });
                    }
                });

                setTimeout(function () {
                    checkSubmitState();
                    eventButton();
                }, 500);
            }, 250);

            heads.each(function() {
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            var assocs_elm = $('#assocs_slider', '#site_global_wrap').get(0);
            var ranges_elm = $('#ranges_slider', '#site_global_wrap').get(0);

            getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist);

            $('#addToWishlistButton', '#site_global_wrap').on('click', addToWishlist);

            checkProductInWishlist(data.produit_id_new, itm_rr_id);

            if (is_wishlist) {
                // event sur le bouton d'ajout à la wishlist
                $("#addToWishlistButton").on("click", addToWishlist);
            }

            if ($(".productSwiper").length) {
                new Swiper(".productSwiper", {
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    navigation: {
                        nextEl: '.rollover_rayonsNext',
                        prevEl: '.rollover_rayonsPrev',
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

            if ($("#size_list_" + new_id + itm_rr_id).length) {
                new Swiper('#size_list_' + new_id + itm_rr_id, {
                    slidesPerView: 7,
                    freeMode: true,
                    scrollbar: {

                        el: '.swiper-scrollbar',
                        hide: false,
                    },
                    navigation: {
                        nextEl: '.arrow_next_' + new_id,
                        prevEl: '.arrow_prev_' + new_id,
                    },
                });
            }

            if ($('.page_produit').length) {
                initProductSizeFieldset('.page_produit');
            }

            //Simulates scroll (2px) to load images
            window.scrollTo(0, $(window).scrollTop()+2);
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup) {
    var control, prod_id, from_page;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express = ($('#is_achat_express').val() == 1 && !is_wishlist);

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value).trim();
        var is_assoc_product = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (is_assoc_product) {
            from_page = 'assoc';
        } else {
            from_page = 'product';
        }

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', from_page);
        } else {

            // On regarde si le produit est dejà dans la wishlist
            if (!is_achat_express) {
                var in_wishlist = checkProductInWishlist();
                changeBtnAddWishlist(in_wishlist, prod_id);
            }

            var img = $(this).data('pic');
            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

/**
 * @param product_id : l'id du produit
 * @param regroup_ref_id : l'id du regroupement (si exist)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {

    $('#is_achat_express_v2').val(1);
    $('.productSizeFieldset').hide();
    $('.productColorFieldset').show();
    $('.bloc_add_color').show();

    $('#ligne_couleur_' + product_id + regroup_ref_id).hide();

    var form = this.form;

    setTimeout(function() {

        waitingAddToBasket(event, form, '', regroup_ref_id);

    }, 200);
}

// Called upon size change in product page
function onItemSizeChange(form, event) {
    var control;
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v2 = false;


    var productSizeFieldset = $(".productSizeFieldset");
    var sizesList = $(".sizesList");
    var shadSelect = $(".shadSelect");

    productSizeFieldset.removeClass("actif");
    sizesList.removeClass("opened");
    shadSelect.removeClass("actif");

    if (this.checked) {
        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        var id= form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id : '';

        $('.choosenSize').html(this.dataset.nom);

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible


        // Pour recharger le bloc quantité
        showQteListe('liste_qte', id, path_relative_root);
        // Pour mettre à jour le formulaire produit
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, is_wishlist);
        // On regarde si le produti est dejà dans la wishlist
        if (!is_achat_express_v2) {
            if (elem == '') {
                checkProductInWishlist($('#produit_principal').val());
            } else {
                checkProductInWishlist($('#produit_principal' + elem).val());
            }
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, is_wishlist) {

    var is_achat_express = $('#is_achat_express_v1').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id : '';

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id);
    var plnb = form.getValue('produit_lot_nb');

    id = ( (id !== undefined) ? id : (($('#lightbox_achat_express').css('display') == 'block') ? $('#lightbox_achat_express #produit_principal').val() : form.getValue('produit_id')) );

    var data = {
        idprod: id,
        idcouleur: 0,
        idtaille: 0,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = id;
    } else {

        // Due to express buy, from == product -> check if body has tunnel basket classes
        if ($('body.cart.step_1').length > 0) {
            data.suff = id;
        }
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            var achat_express_lightbox = $('#lightbox_achat_express').length;
            var lightboxAchatExpressVisible = $('#lightbox_achat_express').is(':visible');

            if (res.stock_tampon == 0) {

                if (typeof achat_express_lightbox != "undefined" && achat_express_lightbox && lightboxAchatExpressVisible) {
                    if (!$("#lightbox_achat_express .w-alertestock-form").length) {
                        new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#lightbox_achat_express #keep_alive_stock');
                    }
                    $('#lightbox_achat_express .bloc_add_alert').show();
                    $('#lightbox_achat_express #bloc_add_basket').hide();
                } else {
                    if (wShop.$refs.wAlertestockForm) {
                        wShop.$refs.wAlertestockForm.reloadBloc += 1;
                    }

                    $(".eclat_product_nouveaute_fiche_produit").show();
                    $(".product_images").addClass('no_stock');
                    $("#buyNowSwiper").addClass('no_stock');
                    $(".bloc_add_alert" + elem).show();
                    $("#prod_info" + elem + " .rollover_left").hide();
                    $("#preorderEclat").hide();

                    if (!is_achat_express) {
                        $("#bloc_add_basket").hide();
                    }

                    $('#ligne_qte').append($('.restock_date'));
                    $('#lastpieceEclat').hide();
                }
            } else {
                if (typeof achat_express_lightbox != "undefined" && achat_express_lightbox && lightboxAchatExpressVisible) {

                    $("#lightbox_achat_express .bloc_add_alert").hide();
                    $("#lightbox_achat_express #bloc_add_basket").show();
                } else {
                    $("#preorderEclat").show();
                    $(".product_images").removeClass('no_stock');
                    $("#buyNowSwiper").removeClass('no_stock');

                    if (res.stock_tampon > 0 && parseInt(res.stock_tampon) <= parseInt(alerte_last_stock)) {
                        $('#lastpieceEclat').show();
                    } else {
                        $('#lastpieceEclat').hide();
                    }

                    if (is_achat_express) {
                        waitingAddToBasket(event, form, '');

                    } else {
                        $(".eclat_product_nouveaute_fiche_produit").hide();
                        $(".bloc_add_alert").hide();
                        $("#bloc_add_basket").show();
                    }
                }
            };

            // Custom quantity selector update for FP and AE lightbox
            if ($('#product_stock_counter').length && parseInt(res.stock_tampon)) {
                $('#product_stock_counter').val(parseInt(res.stock_tampon))
            }

            // Check if globale delivery country
            if (res.disable_addtocart == true) {
                if ( (typeof GEP  != 'undefined' && GEP.isOperatedByGlobale !== undefined &&  GEP.isOperatedByGlobale !== false) || (typeof GEP  == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                    $("#bloc_add_basket").hide();
                    $(".bloc_add_alert").show();
                    $("#productInfos #size_"+size_id).addClass('disabled');
                }
            }
        }
    });
}

$(function () {

    "use strict";

    initProductSizeFieldset('.product_main_wrapper');
    
    /*****TG HOME*****/
    $(".tgHomeSwiper").each(function() {
        var cssName = $( this ).parent().parent().parent().attr( 'data-title' ) || $( this ).parent().parent().attr( 'data-title' );
        var tgSpe = $( "." + cssName );

        if ( tgSpe.find( '.swiper-container .swiper-slide' ).length > 1 ) {
            var thisTg = new Swiper( '.' + cssName + ' .swiper-container', {
                slidesPerView: 3,
                navigation: {
                    nextEl: "." + cssName + " .swiper-button-next",
                    prevEl: "." + cssName + " .swiper-button-prev",
                }
            });   
        }
    });
} );

function initProductSizeFieldset(parent) {
    /**
        Function to simulate the behavior of a select
     */
    $(parent + " .productSizeFieldset").on("click touch", function () {

        var sizesList = $(".sizesList", $(this));
        var shadSelect = $(".shadSelect");

        sizesList.toggleClass("opened");
        $(this).toggleClass("actif");
        shadSelect.addClass("actif");

        shadSelect.on("click touch", function () {

            sizesList.removeClass("opened");
            $(this).removeClass("actif");
            $(parent + " .productSizeFieldset").removeClass("actif");
        });
    });
}

function waitingAddToBasket_legacy(
    produit_id,
    suff,
    panier_id,
    titreObjet,
    idTaille,
    lastTaille,
    idCouleur,
    lastCouleur,
    toEval,
    page_name,
    path_web,
    enable_omniture,
    is_not_produit,
    is_lightbox_specifique
) {
    var id = "";
    if (is_not_produit == true) id += "_" + produit_id;

    if (is_lightbox_specifique)
        add_produit_specifique(produit_id, idCouleur, idTaille);
    else
        window.setTimeout(
            'addToBasket_legacy("' +
                produit_id +
                '", "' +
                suff +
                '", "' +
                panier_id +
                '", "' +
                titreObjet +
                '", "' +
                idTaille +
                '", "' +
                lastTaille +
                '", "' +
                idCouleur +
                '", "' +
                lastCouleur +
                '","' +
                toEval +
                '", "' +
                page_name +
                '", "' +
                path_web +
                '", ' +
                enable_omniture +
                ", " +
                is_not_produit +
                ");",
            1000
        );

    $("#bloc_btn_active" + id).attr("class", "cache");
    $("#bloc_btn_loader" + id).attr("class", "f_right");
}

function addToBasket_legacy(
    produit_id,
    suff,
    panier_id,
    titreObjet,
    idTaille,
    lastTaille,
    idCouleur,
    lastCouleur,
    toEval,
    page_name,
    path_web,
    enable_omniture,
    is_not_produit
) {
    var id = "";

    if (is_not_produit == true) id += "_" + produit_id;

    prix_id = "prixU";
    if (is_not_produit == true) {
        var code_color = $("#couleurUnique_" + produit_id).text();
        var nom_color = $("#couleurUnique_" + produit_id).text();
        var prix_id = "prixU_" + produit_id;
    }

    var array_panier = newUpdatePanier_legacy(
        produit_id,
        suff,
        panier_id,
        document.getElementById(prix_id).value,
        path_web,
        titreObjet,
        idTaille,
        lastTaille,
        idCouleur,
        lastCouleur
    );

    if (array_panier != false) {
        var type = "";

        if (array_panier[1] == "false" || array_panier.length == 0) {
            type = "err";
        }

        omnitureEvent = "";
        var response = ajax_file(path_web + create_link('ajax_show_basket'));
        $("#cart_top").html(response);

        if (array_panier[1] === 0) {
            $("#nb_article").html(array_panier[1]);
            omnitureEvent = "";
        } else if (array_panier[1] == 1) {
            $("#nb_article").html(array_panier[1]);
            $("#panier")
                .attr("href", path_web + create_link("order_basket"))
                .attr("style", "cursor:pointer;");

            omnitureEvent = "scOpen,scAdd"; // le premier produit ajout� au panier
        } else {
            $("#nb_article").html(array_panier[1]);
            $("#panier")
                .attr("href", path_web + create_link("order_basket"))
                .attr("style", "cursor:pointer;");

            omnitureEvent = "scAdd"; // il y a d�j� un produit au panier
        }

        newshowmodal(
            array_panier[1],
            type,
            path_web,
            is_not_produit,
            produit_id
        );

        $("#bloc_btn_active" + id).attr("class", "");
        $("#bloc_btn_loader" + id).attr("class", "f_right cache");

        if (array_panier[1] > 0 && enable_omniture == true) {
            void s.t();
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void s.t();
        }

    } else {
        $("#bloc_btn_active" + id).attr("class", "");
        $("#bloc_btn_loader" + id).attr("class", "f_right cache");
    }

    eval(toEval);

    if (array_panier != false) {
        if (
            navigator.userAgent.indexOf("MSIE") != -1 &&
            navigator.userAgent.indexOf("6.") != -1
        ) {
            var svn = document.getElementsByTagName("SELECT");
            for (a = 0; a < svn.length; a++) {
                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet,
    idElTaille,
    lastTaille,
    idElCouleur,
    lastCouleur
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var $alertbox = $("#alertbox1");
    var res = "";
    var html_err = "";
    var flagError = false;
    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ($alertbox.length == 0) {
        $alertbox = $('<div id="alertbox1">' + html_err + "</div>");
        $("body").append($alertbox.hide());
    }

    if (tailles && tailles.value == "") {
        res = Translator.translate('js_error_size');
        flagError = true;
    } else if (couleurs && couleurs.value == "") {
        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        $alertbox.find(".txt_alert").html(res);
        openMultiShad("alertbox1");
        return false;
    } else {
        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.value != "none") idCouleur = couleurs.value;
        if (tailles && tailles.value != "none") idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file(
            path_web +
                "ajax_upd_panier.php?prixu=" +
                prixU +
                "&idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&qte=" +
                qte +
                "&panierId=" +
                paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;

            if (document.getElementById(idElTaille)) {
                if (document.getElementById(idElTaille).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - Taille " +
                        document.getElementById(idElTaille).options[
                            document.getElementById(idElTaille).selectedIndex
                        ].text;
                } else if (lastTaille != "") {
                    designation += " - Taille " + lastTaille;
                }
            }

            if (document.getElementById(idElCouleur)) {
                if (document.getElementById(idElCouleur).selectedIndex) {
                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation +=
                        " - " +
                        document.getElementById(idElCouleur).options[
                            document.getElementById(idElCouleur).selectedIndex
                        ].text;
                } else if (lastCouleur != "") {
                    designation += " - " + lastCouleur;
                }
            }

            array_response[0] = designation;
        }

        return array_response;
    }
}

/**
 * Peremt de récupérer les stocks des tailles par couleur ainsi que leur stock
 * @author Timothy Beard / Aurélien Renault
 * @since  2017-09-27
 */
function setAvailableSizes() {
    if (window.json_colisages !== undefined) {
        var sizes_tab = window.json_colisages[$(this).val()];
        var size_box, is_disabled;

        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $('.product_main_wrapper [name="itm_size"]').filter(
                    '[value="' + size_id + '"]'
                );
                is_disabled = !sizes_tab[size_id];
                if ( is_disabled )  {
                    size_box.addClass('disabled');
                }
                else {
                    size_box.removeClass('disabled');
                }
            }
        }
    }
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader = $('.bloc_add_basket.form_submit .loader');

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express);
    }
}


function addToBasket(produit_id, suff, panier_id, titreObjet, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express) {

    var id = '';

    prix_id = 'prixU';

    from = (from == 'panier') ? 'basket' : from;
    path_web = Number.isInteger(path_web)? '' : path_web;

    if (from == 'basket' || is_not_produit == true || is_achat_express) {

        id += '_' + produit_id;
        prix_id = 'prixU' + id;

        if (is_not_produit == true) {

            var code_color = $('#couleurUnique' + id).text();
            var nom_color = $('#couleurUnique' + id).text();
        }
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, document.getElementById(prix_id).value, path_web, titreObjet);

    if (array_panier != false) {

        if (from == 'basket') {

            location.reload();

            return false;
        }

        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0) {

            type = 'err';
        }

        omnitureEvent = '';

        if (array_panier[1] == 1) {

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        } else {

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }

        if (array_panier[1] > 0 && enable_omniture == true) {

            void(s.t());
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void(s.t());
        }

        if (array_panier.length >= 0 && array_panier[5] !== undefined) {
            pushIntoDatalayer(array_panier[5]);
        }

        // Mise à jour du panier top
        var response_basket = ajax_file(path_web + 'ajax_show_basket.php');

        $("#cart_top").html(response_basket);

        var count_bask = $('#cart_top .cart').attr('data-cart');

        if (count_bask > 2) {
            $("#cart_list").overlayScrollbars({});
        }

        $("#lightbox_achat_express, #shad").removeClass('actif');
        
        newshowmodal(produit_id, is_not_produit);
    } else {

        if (loader) {

            loader.removeClass('loading');
        } else {

            $("#bloc_btn_active" + id).attr('class', '');
            $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
        }
    }

    eval(toEval);

    if (array_panier != false) {

        if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1) {

            var svn = document.getElementsByTagName("SELECT");

            for (a = 0; a < svn.length; a++) {

                svn[a].style.visibility = "hidden";
            }
        }
    }

    return false;
}

// Pretty straightforward: this formats a price
var formatPrice = (function() {
    var THREEDIGITS = new RegExp("\\d{3}", "g");
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands(nmbr) {
        var f_chunk = "",
            chunk_length;

        nmbr = String(nmbr);

        chunk_length = nmbr.length % 3;

        if (chunk_length) {
            f_chunk = nmbr.slice(0, chunk_length);
            nmbr = nmbr.substr(chunk_length);
        }

        return (
            f_chunk +
            nmbr.replace(THREEDIGITS, function(match) {
                return " " + match;
            })
        );
    }

    return function(price) {
        var CURRENCY = window.siteCurrency;

        var output;

        if (price.constructor !== String) {
            price = price.toString(10);
        }

        price = price.replace(".", ",");
        price = price.split(",");

        output = FORMAT.replace("[<unit>]", splitThousands(price[0]));
        output = output.replace("[<symbol_right>]", CURRENCY.symbol_right);
        output = output.replace("[<symbol_left>]", CURRENCY.symbol_left);

        if (price[1] === undefined) {
            price[1] = "00";
        } else if (parseFloat(price[1]) < 10) {
            price[1] = "0" + price[1];
        }

        output = output.replace("[<decimal>]", price[1]);
        output = output.replace("[<code>]", CURRENCY.code);

        return output;
    };
})();

// Limits the length of an input
// oninput="limitInputLength.call(this, 100)"
// input.addEventListener('input', limitInputLength.bind(input, 100))
// $(input).on('input', limitInputLength.bind(input, 100))
function limitInputLength(max) {
    this.value = this.value.slice(0, max);

    return this;
}


// ACCORDION CARACTERISTIQUE PAGE PRODUIT
$('.accordion > ul > li:has(ul)').addClass("has-sub");
$('.accordion > ul > li > a').click(function() {

    var checkElement = $(this).next();

    $('.accordion li').removeClass('active');
    $(this).closest('li').addClass('active');

    if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
        $(this).closest('li').removeClass('active');
        checkElement.slideUp('normal');
    }

    if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
        $('.accordion ul ul:visible').slideUp('normal');
        checkElement.slideDown('normal');
        $(this).closest('li').addClass('active');
    }

    if (checkElement.is('ul')) {
        return false;
    } else {
        return true;
    }
});



// INSCRIPTION NEWSLETTER
// La gestion de l'inscription à la newsletter
$("input#newsletter_home").click(function() {
    $.ajax({
        url: path_relative_root + create_link('ajax_newsletter_email'),
        type: "post",
        data: {
            email: $("#newsletter_email").val(),
            provenance: "footer",
            sous_provenance: $("#newsletter_email").attr("data-lang"),
        },
        cache: false,
        success: function(res) {
            var resultat = JSON.parse(res);
            if (resultat.valid == false) {
                $("#newsletter_email").addClass("inputErr");
            } else {
                $("#error_newsletter").removeClass("error");
                $("#error_newsletter").addClass("valid");
                $("#newsletter_email").removeClass("inputErr");
            }
            $("#error_newsletter").html(Translator.translate(resultat.message));
        },
    });
});


/*
    Permet l'envoi du mail qui confirme l'enregistrement de sont email,
    pour alerter la personne quand le produit sera disponible
*/
function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $type_alert = type_alert;
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: 0,
                couleur_id: 0,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('.bloc_add_alert' + elem + ' .alert_return').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();
    $('.alert_return').hide();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).show();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.form_submit.bloc_add_color').show();
}

// YOUTUBE LOGIC
var YT_Player, YT_vid_id;

function onYouTubeIframeAPIReady() {

    YT_Player = new YT.Player('popup_video', {
        videoId : YT_vid_id,
        playerVars : {
            rel : 0
        }
    });

    if ($('#videoBox').is(':visible')) {

        YT_Player.playVideo();
    }
}

function initYTPlayer() {

    var tag = document.createElement('script');

    tag.id = 'YT_player_script';
    tag.src = "https://www.youtube.com/iframe_api";

    document.body.appendChild(tag);
}

/**
 * La video dans la fiche produit (champs produit_video) Charge une video à partir de Youtube
 */
function show_video(vid) {

    var video = $('#videoBox');

    $(window).scrollTop(0);

    if (!YT_Player) {

        YT_vid_id = vid;

        if (!document.getElementById('YT_player_script')) {

            initYTPlayer();
        } else {

            onYouTubeIframeAPIReady();
        }
    }

    $('#shad').off('click', stop_video).after(video);
    $('#shad').stop(true, true).fadeIn('slow', function () {

        video.css({ display : 'block', opacity : 0 }).animate({ opacity : 1 }, function () {

        });

        $('#shad').one('click', stop_video);
    });
}

function stop_video() {

    var video = $('#videoBox');

    if ( video.css('display') != 'none' ) {
        video.css({ display : 'block', opacity : 1 }).animate({ opacity : 0 }, function () {

            $('#shad').stop(true, true).fadeOut('slow', function () {

                if (YT_Player !== undefined) {

                    YT_Player.seekTo(0).stopVideo();
                }

                video.css({ display : 'none' });
            });
        });
    }
}

//declare function font-weight customization for titles, product names etc.
function fontWeightObject(element, sep) {
    var sep;
    var element;
    var separator = element.html().trim().split(sep);
    element.html('<strong>' + separator.shift() + sep +'</strong>' + separator.join(sep));
}
/**
* Customize font-weight based on separator (" ") product name (first word) - tunnel
**/
if ($('.wrapper_cart_product_desc .cart_product_title').length > 0) {
    $('.wrapper_cart_product_desc .cart_product_title').each(function() {
        var sep = ' ';
        var element = $(this);
        fontWeightObject(element, sep);
    });
}
/**
* Customize font-weight based on separator (" ") product name (first word) - panier
**/
if ($('.cart_detail_box .cart_product .title').length > 0) {
    $('.cart_product .title').each(function() {
        var sep = ' ';
        var element = $(this);
        fontWeightObject(element, sep);
    });
}

/**
* Customize font-weight based on separator (".") store name - store detail
**/
if ($('.masgasin_pages .storeDetails .title').length > 0) {
    $('.storeDetails .title').each(function() {
        var sep = '.';
        var element = $(this);
        fontWeightObject(element, sep);
    });
}

function close_popup(elem) {
    var $modbox = $("#" + elem);
    var $shad = $("#shad");
    $shad.attr("onclick", "");
    $shad.animate({opacity: 0}, 350, function() {
        $shad.css({display: "none", opacity: ""}).data("opened_lb", 0);
    });
    $modbox.css({display: "none"});
    $modbox.removeClass('actif');
}

function openMultiShad(id) {
    // Show modbox and make sure closing routine won't fire more than once
    $("#" + id).fadeIn(200, function () {
    $("#" + id).slideDown(600);
    if ($("#" + id).hasClass('actif')) {
        $("#" + id).removeClass('actif');
    }
});

    $("#" + id).addClass('actif');

    if (id !== 'zoomBox') {
        if (!$("#shad").hasClass('actif')) {
            $("#shad").addClass('actif');
        }
        $("#shad").on("click", function () {
            closeMultiShad(id);
        });
    }

    if (id == 'popup_dispatch') {
        $('#shad').addClass('dispatch_shad');
    }

    if (id == "lightbox_achat_express") {
        var prod_id,
        form,
        prod_qty,
        prod_rr_id,
        prod_is_wish;
        prod_id = $('#lightbox_achat_express #produit_id').val();
    }

    if (id === "zoomBox") {

        $('#zoom_box_wrapper .img_large').on('click', function () {

            closeMultiShad('zoomBox');
        });

        $('body').css('position','fixed');
    }

    if (id === 'popup_savoir_plus') {
        $("#popup_savoir_plus").css("display", "block");
        $('#shad').css('display', 'block');
    }

    if ($("#buyNowSwiper").length > 0) {

        window.setTimeout(function () {
            buyNowSwiper = new Swiper("#buyNowSwiper", {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        }, 50);
    }

    if (id === "sizeguideBox") {
        window.setTimeout(function () {
            $("#wrap_itable").overlayScrollbars({});
        }, 300);

        $('body').css('position','fixed');
    }

      // Shipup Lightbox
      if (id === "shipup-container") {

        // We check if theres the top-navbar and we add a close button to the lightbox
        if ($('.shipup-top-navbar', "#" + id).length && !$('.custom_shipup_close', "#" + id + " .shipup-top-navbar").length) {
            setTimeout(function() {
                $('.shipup-top-navbar', "#" + id).append('<div class="custom_shipup_close" onclick="closeMultiShad(\'' + id + '\')"><span class="bfr bgSprite cross"></span></div>');
            }, 500);
        }

        setTimeout(function() {
            $("#" + id).find('.shipup-home-container').overlayScrollbars({
                scrollbars: {
                    autoHide: "leave",
                    autoHideDelay: 0,
                }
            });
        }, 500);
    }

    $(function() {
        window.setTimeout(function() {
            if ($("#thumbs_scroller").length > 0) {
                var zoomThumbs = new Swiper('#thumbs_scroller', {
                    slidesPerView: 'auto',
                    spaceBetween: 14,
                    direction: 'vertical',
                    freeMode: true,
                    watchSlidesVisibility: true,
                    watchSlidesProgress: true,
                });
            }

            if ($("#zoom_box_wrapper .swiper-slide").length > 0) {
                var main_productVisu = new Swiper('#zoom_box_wrapper', {
                    effect: 'fade',
                    slidesPerView: 1,
                    thumbs: {
                        swiper: zoomThumbs
                    },
                    preloadImages: false,
                    lazy: true,
                    loadPrevNext: true,
                    loadOnTransitionStart: true
                });
            }

        }, 500);
    });

    // Tous nos contacts lightbox
    if (id == "lightbox_nos_contacts") {
        $( "#shad" ).addClass('for_lb_contacts');
        $( "#shad" ).show();
    }

    if (id == 'selection-offer') {
        setTimeout(function () {

            $("#selection-offer-content").lionbars({});
        }, 1000);
    }

    // Ask for a quote
    if (id == 'lightbox_devis_form') {

        setTimeout(function () {

            $('html, body').animate({
                scrollTop: 0
            }, 800);
        }, 150);
    }
}

function closeMultiShad(id) {
    $('body').css('position','');
    $("#" + id).removeClass('actif');
    $("#shad").removeClass('actif');

    // Tous nos contacts lightbox
    if (id == "lightbox_nos_contacts") {
        $( "#shad" ).removeClass('for_lb_contacts');
    }

    $('#shad').off('click');
    $("#availabilityInStore").slideUp(600);

    $('#' + id).fadeOut("slow").queue(function () {

        if ($('#shad').is(":visible")) {
            $('#shad').fadeTo("slow", 0, function () {
                $('#shad').css('display', 'none');
            });
        }

        if ($('#shad_abox').is(":visible")) {
            $('#shad_abox').fadeTo("slow", 0, function () {
                $('#shad_abox').css('display', 'none');
            });
        }

        $(this).dequeue();
    });

    if ( $('.product_infos_form.not_achat_express #product_selects').html() == '' ) {
        $('.product_infos_form.not_achat_express #product_selects').html(main_product_select_html);
    }

    if (id == "sendwishlistbox") {
        $("#sendwishlistbox").hide();
        $('#shad').css('opacity', '0');
    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/
function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

/***** PROD ASSOCS DATA LAYERS *****/
function dataLayer_associated_product () {

    if (window.$('#gondole_complete')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';
        var productObj = null;
        if (product_id !== '') {
            if (data_layer_products.length > 0) {
                data_layer_products.forEach(function(item) {
                    if (item.id == product_id) {
                        productObj = item;
                    }
                })
            }
            var prod = productObj;
            if (productObj !== null && productObj !== undefined ) {
                dataLayer.push({
                    'event': 'productClick',
                    'ecommerce': {
                        'click': {
                            'actionField': {'list': productObj['category']},      // Optional list property.
                            'products': [prod]
                        }
                    },
                    'eventCallback': "function () {document.location = productObj.url}"
                });
            }
        }
    }
}

/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};

function changeVisu(field, path, evt) {
    var new_img, target, current;

    field = document.getElementById(field);

    if (evt) {
        target = evt.target.parentElement;
        current = target.parentElement.getElementsByClassName("current");

        target.classList.add("current");

        Array.prototype.slice.call(current).forEach(function(elm) {
            if (elm !== target) {
                elm.classList.remove("current");
            }
        });
    }

    if (field && field.parentElement) {
        new_img = new Image();

        new_img.id = field.id;
        new_img.className = field.className;

        new_img.addEventListener("load", function() {
            this.alt = field.alt;

            this.setAttribute("width", this.naturalWidth);
            this.setAttribute("height", this.naturalHeight);

            if (field.parentElement) {
                field.parentElement.appendChild(this);
                field.parentElement.removeChild(field);
            }
        });

        new_img.src = path;
    }

    var productSwiper = new Swiper(".productSwiper", {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        navigation: {
            nextEl: '.rollover_rayonsNext',
            prevEl: '.rollover_rayonsPrev',
        },
        preloadImages: false,
        lazy: true,
        loadPrevNext: true,
        loadOnTransitionStart: true
    });
}

function newshowmodal(products, type, root, id_produit, is_not_produit)
{
    topCartAppear();
    window.setTimeout(function () {

        topCartDesappear();
    }, 5000);
}

function topCartAppear() {
    var show_top_cart_parent = $('#cart_top');
    var top_wrapper = $('#top_wrapper');
    var count_bask = $('#cart_top .cart').attr('data-cart');

    if ($('#show_top_cart').length) {
        $('#show_top_cart .holder_scrollable').overlayScrollbars({});
    }

    if (count_bask > 2) {
        $(".cart_product_listing").overlayScrollbars({});
    } else if (count_bask == 0) {

        if ($(window).height() < 720 ) {
            $(".no_product", ".cart_box.noprod").overlayScrollbars({});
        }

    }

    show_top_cart_parent.addClass("hover");
    top_wrapper.addClass('active');

    $('#shad_menu').addClass("actif");
}

/**
 * Pour afficher le panier sur le coté (utilisé aussi dans product.js à l'ajout du panier)
 */
function topCartDesappear() {
    var show_top_cart_parent = $('#cart_top');
    var top_wrapper = $('#top_wrapper');
    show_top_cart_parent.removeClass("hover");
    top_wrapper.removeClass('active');
    $('#shad_menu').removeClass("actif");
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#texte_question_" + i).css('display') == 'block') {

            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {

        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {

        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

/**
PRODUCT PAGE ZOOM
*/
function zoomImg(slide) {


    var slide_img = $(slide);
    var slide;
    if (slide_img.hasClass('video_zoom_trigger')) {
        slide = slide_img.closest('.slick-slide');
    } else {
        slide = slide_img.parent('.slick-slide');
    }
    var slide_index = slide.data('slick-index') - 1;

    $('#zoom_box_wrapper').css('opacity', 0);

    window.setTimeout(function () {

        $('#zoom_box_wrapper').css('opacity', 1);
    }, 350);

    openMultiShad('zoomBox');

    // NB : slide - 1 because current slide isn't the big image but the one to its left
    if ($('.productZoomSlick').length) {
        if (!$('.productZoomSlick').hasClass('slick-initialized')) {
            $('.productZoomSlick').slick({
                slidesToShow: 6,
                lazyLoad: 'ondemand',
                initialSlide: slide_index
            });
        } else {
            $('.productZoomSlick').slick('slickGoTo', slide_index)

        }
        $('.productZoomSlick .slick-slide').on('click', function(e) {
            e.preventDefault();
            $('.productZoomSlick').slick('slickGoTo', ($(this).data('slick-index') - 1));
        });
    }
}

/**
 * Dans la fiche produit affiche un champs email pour que l'ont previenne que le produit est disponible
 */
function lightboxAlertStock() {

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {

        if (isFormVisible) {

            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {

            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {

        $('.bloc_add_alert_form').slideDown('fast');
    }
}

function filterTriggerIn(event) {
    // class added on input when user filters
    $(event.target).toggleClass("is_checked");
}

// count filters for attributes options in rayon
function countItemFilter(input, translation) {
    var filterName = input.name;
    var filters = $('#filter_caract_' + translation + ' input:checked[name='+ filterName +']');

    var countFilters = filters.length;

    if (countFilters > 0) {
        $("#filter_caract_" + translation + " .trigger_filtre[data-filtername='" + filterName + "'] .count_filters").html('(' + countFilters + ')');
    }

    if (countFilters === 0) {
        $("#filter_caract_" + translation + " .trigger_filtre[data-filtername='" + filterName + "'] .count_filters").empty();
    }

}

function processDisplayProductInList(obj, result) {
    var has_stock = false

    //On regarde s'il y a du stock dans notre produit
    has_stock = ((result.stock_quantite != undefined) && result.stock_quantite > 0) ? true : false;

    if (has_stock) {
        obj.parent('.item').removeClass('out_of_stock');
    }
    else {
        obj.parent('.item').addClass('out_of_stock');
        obj.find("#eclat_new").hide();
    }

    store_exclusivity = obj.find(".specifics_eclats").data('store-exclusivity');
    web_exclusivity = obj.find(".specifics_eclats").data('web-exclusivity');

    //On regarde si le stock est limité dans notre produit
    if (result.stock_quantite <= 25 && has_stock && !web_exclusivity && !store_exclusivity) {
            obj.find("#eclat_stock_limited").show();
            obj.find(".specifics_eclats").hide();
    } else {
            obj.find("#eclat_stock_limited").hide();
    }
}

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};


function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {
    var objElementHidden = document.getElementById(elementID + "Hidden");
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById("erreur_msg");

    if (objElementHidden !== null) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == "337") {
            if (elementID === "clientTel") {
                $("#clientMobileHidden").val(value);
            } else if (elementID == "telephone") {
                $("#mobileHidden").val(value);
            } else if (elementID == "billtelephone") {
                $("#billmobileHidden").val(value);
            } else if (elementID == "candidatTel") {
                $("#candidatTelHidden").val(value);
            }
        } else {
            if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                (type === 0 || type === 2 || type === 3 || type === 6)
            ) {
                $(objElementHidden).val(value);
            } else if (
                $(objElement).intlTelInput("isValidNumber") !== false &&
                type === 1
            ) {
                if (elementID === "clientTel") {
                    $("#clientMobileHidden").val(value);
                } else if (elementID == "telephone") {
                    $("#mobileHidden").val(value);
                } else if (elementID == "billtelephone") {
                    $("#billmobileHidden").val(value);
                } else if (elementID == "candidatTel") {
                    $("#candidatTelHidden").val(value);
                }
            }
        }

        if ($(objElement).intlTelInput("isValidNumber") === false) {
            $(objElement).addClass("inputErr");

            return false;
        }

        $(objElement).removeClass("inputErr");

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
        if (
            $(objElement).intlTelInput("isValidNumber") === false ||
            (is_mobile && $(objElement).intlTelInput("getNumberType") === 0) ||
            (is_mobile == false &&
                $(objElement).intlTelInput("getNumberType") === 1)
        ) {
            if (
                is_mobile &&
                $(objElement).intlTelInput("getNumberType") === 0
            ) {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file(
                        "ajax_sprintf.php?arg2=" + fieldname + "_mobile"
                    ) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_mobile&arg2=" +
                        fieldname
                    );
            } else {
                erreurText.innerHTML =
                    '<p class="title_toolltip">' +
                    ajax_file("ajax_sprintf.php?arg2=" + fieldname) +
                    " :</p>" +
                    ajax_file(
                        "ajax_sprintf.php?arg1=js_error_phone&arg2=" + fieldname
                    );
            }

            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    $(objElement).addClass("inputErr");

    return false;
}

$(document).ready(function () {
    // Assign first step on multi destination delivery
    if ($('.choix_delivery_multiple.bloc_livraison').length) {
        $('.choix_delivery_multiple.bloc_livraison').find('#infos_livr_multiple').addClass('on_step_one');
    }

    // Change translation on address form on step one
    if ($('body.cart.in_tunnel').length && $('.choix_delivery_multiple').length) {
        if ($('#adresseForm').length) {
            var formButton = $("#adresseForm .w-submit-button:first");
            var buttonSpan = formButton.find('span');
            buttonSpan.text(Translator.translate('add_address_multidestination'));
        }
    }

    // Select delivery option
    if ($('body.cart.in_tunnel').length && (!$('body.step_2').length)) {
        var multidestinationBloc = $('.choix_delivery_multiple.bloc_livraison');
        if (multidestinationBloc.length) {
            multidestinationBloc.addClass('active');
        }
    }

    // Fade in page, fix steps modification button and wording appearing on load
    if ($('body.livraison-multiple').length) {
        $('body.livraison-multiple').delay(500).fadeIn(500);
    }

    function updateCartElements() {
        // Check Warning msg display on Cart Page
        // Excluded product case
        var btn = $("#formPanier #btn_cmd_valid");
        if (document.querySelector('.cart_product_excluded') != null) {
            // Disable Cart Validation button
            btn.attr("disabled", "disabled");
        } else {
            btn.removeAttr("disabled");
            $("#panier_custom_message").hide();
        }
    }

    // Call the function to initialize the state based on the current content
    updateCartElements();
});

// Get stock info for main product
function getProductDetail(productId) {

    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + productId);

    var pcolor = form.getValue('idCouleur');
    let val_color = form.getValue(pcolor);

    let psize = form.getValue('idTaille');
    let val_size = form.getValue(psize);

    let data = {
        idprod: productId,
        idcouleur : val_color,
        idtaille : val_size,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof(res) == 'object') {
                pushIntoDatalayer(res.dataLayer_object);
            }
        }
    });
}

// DATALAYER FUNCTION
function addToCart(rank) {}

function removeFromCart(rank) {}